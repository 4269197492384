<template>
  <span class="add-adhesion-button" v-if="hasPerm('adhesions.add_adhesiontype')">
    <a href @click.prevent="showModal" class="btn btn-sm btn-primary">
      <i class="fa fa-plus"></i>
      Ajouter
    </a>
    <b-modal
      dialog-class="modal-lg"
      :id="getId()"
      :ok-disabled="formInvalid"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Ajouter"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-id-card"></i> Ajouter une carte d'adhésion</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="name-group"
            label="Nom"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="name"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="apply-to-group"
            label="Type de carte"
            label-for="apply-to"
          >
            <b-form-select
              id="apply-to"
              v-model="applyTo"
              >
              <b-form-select-option
                :value="item"
                v-for="item in applyToChoices"
                :key="item.id"
              >
                {{ item.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="analytic-account-group"
            label="Compte analytique"
            label-for="analytic-account"
          >
            <b-form-select
              id="analytic-account"
              v-model="selectedAnalytic"
              >
              <b-form-select-option
                :value="analyticAccount"
                v-for="analyticAccount in analyticAccounts"
                :key="analyticAccount.id"
              >
                {{ analyticAccount.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="general-account-group"
            label="Compte général"
            label-for="general-account"
          >
            <b-form-select
              id="general-account"
              v-model="selectedGeneral"
              >
              <b-form-select-option
                :value="generalAccount"
                v-for="generalAccount in generalAccounts"
                :key="generalAccount.id"
              >
                {{ generalAccount.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-for="schoolYear of schoolYears" :key="schoolYear.id">
        <b-col>
          <b-form-group
            :id="'schoolYear-group'+schoolYear.id"
            label="Année scolaire"
            :label-for="'schoolYear'+schoolYear.id"
          >
            <b-form-input
              :id="'schoolYear'+schoolYear.id"
              :value="schoolYear.name"
              type="text"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
          :id="'price-group'+schoolYear.id"
          label="Prix"
          :label-for="'price'+schoolYear.id"
          >
            <decimal-input
              :id="'price'+schoolYear.id"
              v-model="prices[schoolYear.id]"
              :disabled="unavailables[schoolYear.id]"
              required
            ></decimal-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :id="'group-unavailable'+schoolYear.id"
            :label-for="'unavailable'+schoolYear.id"
            >
            <br />
            <b-form-checkbox
              :id="'unavailable'+schoolYear.id"
              :checked="unavailables[schoolYear.id]"
              :name="'unavailable'+schoolYear.id"
              :value="true"
              :unchecked-value="false"
              @change="onChangeAvailability(schoolYear, $event)"
            >
              Indisponible
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput'
import { BackendMixin } from '@/mixins/backend'
import { makeChoice } from '@/types/base'
import { FAMILY_ADHESION, INDIVIDUAL_ADHESION, makeAdhesionType } from '@/types/adhesions'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'
import { makeAnalyticAccount, makeGeneralAccount } from '@/types/payments'

export default {
  name: 'add-adhesion-type-button',
  mixins: [BackendMixin],
  props: {
    schoolYears: Array,
  },
  components: {
    DecimalInput,
  },
  data() {
    return {
      randomId: getRandomId(),
      errorText: '',
      name: '',
      applyTo: null,
      prices: {},
      unavailables: {},
      analyticAccounts: {},
      generalAccounts: {},
      selectedAnalytic: null,
      selectedGeneral: null,
    }
  },
  computed: {
    applyToChoices() {
      return [
        makeChoice({ id: FAMILY_ADHESION, name: 'Adhésion familiale', }),
        makeChoice({ id: INDIVIDUAL_ADHESION, name: 'Adhésion individuelle', })
      ]
    },
    formInvalid() {
      return (this.name === '') || (this.applyTo === null)
    },
  },
  watch: {
    schoolYears: function() {
      this.initPrices()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.init()
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-add-adhesion-type-button' + this.randomId
    },
    initPrices() {
      for (let schoolYear of this.schoolYears) {
        this.prices[schoolYear.id] = 0
        this.unavailables[schoolYear.id] = false
      }
    },
    onChangeAvailability(schoolYear, value) {
      this.unavailables[schoolYear.id] = value
      this.unavailables = { ...this.unavailables, }
    },
    async loadAnalyticAccounts() {
      let url = '/api/analytic-accounts/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.analyticAccounts = resp.data.map(
          elt => makeAnalyticAccount(elt)
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadGeneralAccounts() {
      let url = '/api/general-accounts/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.generalAccounts = resp.data.map(
          elt => makeGeneralAccount(elt)
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async onSave() {
      const prices = this.schoolYears.map(
        schoolYear => {
          if (this.unavailables[schoolYear.id]) {
            return {
              school_year: schoolYear.id,
              price: null,
            }
          } else {
            return {
              school_year: schoolYear.id,
              price: this.prices[schoolYear.id],
            }
          }
        }
      )
      this.errorText = ''
      const data = {
        name: this.name,
        apply_to: this.applyTo.id,
        prices: prices,
        analytic_account: this.selectedAnalytic ? this.selectedAnalytic.id : null,
        general_account: this.selectedGeneral ? this.selectedGeneral.id : null,
      }
      let backendApi = new BackendApi('post', '/api/adhesion-types/')
      try {
        let resp = await backendApi.callApi(data)
        const adhesionType = makeAdhesionType(resp.data)
        await this.addSuccess('Le type d\'adhésion a été enregistré')
        this.$bvModal.hide(this.getId())
        this.$emit('done', { adhesionType: adhesionType, })
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    init() {
      this.name = ''
      this.applyTo = null
      this.prices = {}
      this.unavailables = {}
      this.initPrices()
      this.loadAnalyticAccounts()
      this.loadGeneralAccounts()
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
</style>
